import React from "react";
import Form1 from "../components/Form1";
import Form2 from "../components/Form2";
import Form3 from "../components/Form3";
import UserNavbar from "../components/UserNavbar";
import { useStateContext } from "../contexts/ContextProvider";

import { useEffect } from "react";
import { Dropdown, Ripple, initTE } from "tw-elements";

const Dashboard = () => {
  const { dashboardCurrentComp } = useStateContext();

  useEffect(() => {
    initTE({ Dropdown, Ripple });
  }, []);

  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <UserNavbar />
      {dashboardCurrentComp === "form1" && <Form1 />}
      {dashboardCurrentComp === "form2" && <Form2 />}
      {dashboardCurrentComp === "form3" && <Form3 />}
    </div>
  );
};

export default Dashboard;

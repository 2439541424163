import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  // login state variable
  const [usernameLogin, setUsernameLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  // signup state variable
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  // form1
  const [dashboardForm, setDashboardForm] = useState({
    manufacturer: "",
    manufacturerName: "",
    model: "",
    modelName: "",
    damage: [],
    ort: "",
    modelPrice: "",
    isPhoneNew: false,
  });
  const [dashboardForm2, setDashboardForm2] = useState({
    planId: "",
    workshopName: "",
    workshopEmail: "",
    price: "",
  });
  const [dynamicUserName, setDynamicUserName] = useState("");
  const [dynamicUserId, setDynamicUserId] = useState("");
  const [dynamicUserData, setDynamicUserData] = useState({});
  const [dashboardCurrentComp, setDashboardCurrentComp] = useState("form1");
  const [inputs, setInputs] = useState([
    { value: "", title: "VORNAME" },
    { value: "", title: "NAME" },
    { value: "", title: "STRAßE, NR" },
    { value: "", title: "PLZ, ORT" },
    { value: "", title: "TELEFON" },
    { value: "", title: "E-MAIL" },
    { value: "", title: "IMEI" },
  ]);
  const [loader, setLoader] = useState(false);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        username,
        setUsername,
        password,
        setPassword,
        companyName,
        setCompanyName,
        address,
        setAddress,
        phone,
        setPhone,
        viewPassword,
        setViewPassword,
        usernameLogin,
        setUsernameLogin,
        passwordLogin,
        setPasswordLogin,
        dashboardForm,
        setDashboardForm,
        dashboardForm2,
        setDashboardForm2,
        dynamicUserName,
        setDynamicUserName,
        dashboardCurrentComp,
        setDashboardCurrentComp,
        inputs,
        setInputs,
        loader,
        setLoader,
        dynamicUserId,
        setDynamicUserId,
        dynamicUserData,
        setDynamicUserData,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { serverHost } from "../config";
// import { useStateContext } from "../contexts/ContextProvider";
// import Loader from "./Loader";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader";
import { NavLink } from "react-router-dom";
import { Tab, initTE } from "tw-elements";

// for dailogue
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

///////
const columns = [
  {
    id: "status",
    label: "Auftragsstatus",
    minWidth: 300,
  },
  {
    id: "repairShopName",
    label: "Werkstatt",
    minWidth: 170,
  },
  {
    id: "repairShopAddress",
    label: "Adresse der Werkstatt",
    minWidth: 250,
  },
  {
    id: "clamierUserEmailAddress",
    label: "Email des Kunden",
    minWidth: 250,
  },
  {
    id: "clamierAddress",
    label: "Adresse des Kunden",
    minWidth: 250,
  },
  {
    id: "manufacturer",
    label: "Hersteller",
    minWidth: 170,
  },
  {
    id: "model",
    label: "Modell",
    minWidth: 170,
  },
  {
    id: "damagePart",
    label: "Schaden",
    minWidth: 170,
  },
  {
    id: "planprice",
    label: "Reparaturpreis",
    minWidth: 300,
  },
];

const Jobs = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [claimList, setClaimList] = useState([]);
  const [requestedClaimList, setRequestedClaimList] = useState([]);
  const { setLoader, dynamicUserId } = useStateContext();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calDoublePlanPriceTemp = (plan) => {
    let price = 0;
    plan.forEach((element) => {
      price += Number(element.price);
    });
    return String(price);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fetchData = () => {
    fetch(`${serverHost}/api/ppvs/claims/company/${dynamicUserId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoader(false);
        console.log({ res });
        setClaimList(res.singleUserClaims);
      })
      .catch((err) => {
        console.log({ err });
      });

    fetch(`${serverHost}/api/ppvs/claims/requested/company/${dynamicUserId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoader(false);
        console.log({ res });
        setRequestedClaimList(res.success);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleUpdateStatus = (info, status) => {
    setLoader(true);
    const data = {
      status,
      manufacturer: info.phoneManufacture,
      modal: info.phoneModal,
      damages: info.damages.join(", "),
      price: info.price + " €",
      ppvs_email: info.shop[0].emailAddress,
      imei: info.imei,
      ownerEmail: info.emailAddress,
    };
    fetch(`${serverHost}/api/ppws/claims/requested/updateStatus/${info._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchData();
        setLoader(false);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    initTE({ Tab });
  }, []);

  useEffect(() => {
    setLoader(true);

    dynamicUserId && fetchData();
    // eslint-disable-next-line
  }, [setClaimList, setLoader, dynamicUserId]);

  const [open, setOpen] = React.useState(false);
  const [singleJob, setSingleJob] = useState({});

  const handleClickOpen = (row) => {
    console.log({ row });
    setSingleJob({
      ...singleJob,
      customerName: row.firstName + " " + row.surName,
      customerEmailAddress: row.emailAddress,
      customerAddress: row.streetNumber + " , " + row.postalCodeCity,
      customerPhone: row.phoneNumber,
      jobStatus: row.status,
      customer_imei: row.imei,
      repairShopName:
        row.plan.length > 0
          ? row.plan[0].shop.length > 0
            ? row.plan[0].shop[0].companyName
            : "Gelöscht"
          : "Gelöscht",
      repairShopEmailAddress:
        row.plan.length > 0
          ? row.plan[0].shop.length > 0
            ? row.plan[0].shop[0].emailAddress
            : "Gelöscht"
          : "Gelöscht",
      repairShopPhone:
        row.plan.length > 0
          ? row.plan[0].shop.length > 0
            ? row.plan[0].shop[0].phoneNumber
            : "Gelöscht"
          : "Gelöscht",
      repairShopAddress:
        row.plan.length > 0
          ? row.plan[0].shop.length > 0
            ? row.plan[0].shop[0].zipCode +
              " - " +
              row.plan[0].shop[0].ort +
              " - " +
              row.plan[0].shop[0].streetAddress +
              " " +
              row.plan[0].shop[0].houseNumber
            : "Gelöscht"
          : "Gelöscht",
      manufacturer:
        row.plan.length > 0 ? row.plan[0].manufacturer[0].name : "Gelöscht",
      model:
        row.plan.length > 0
          ? row.plan[0].manufacturer[0].models[0].modalName
          : "Gelöscht",
      modelPrice:
        row.plan.length > 0
          ? row.plan[0].manufacturer[0].models[0].modalPrice
          : "Gelöscht",
      damageParts:
        row.plan.length > 0
          ? row.plan.length > 1
            ? row.plan.map((el) => el.damagePart[0].damagePart + ", ")
            : row.plan[0].damagePart[0].damagePart
          : "Gelöscht",
      planPrice:
        row.plan.length > 0
          ? row.plan.length > 1
            ? calDoublePlanPriceTemp(row.plan)
            : row.plan[0].price
          : "Gelöscht",
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const customerInfo = [
    {
      label: "Kundenname ",
      value: singleJob.customerName,
    },
    {
      label: "E-Mail-Addresse",
      value: singleJob.customerEmailAddress,
    },
    {
      label: "Telefonnummer ",
      value: singleJob.customerPhone,
    },
    {
      label: "Adresse ",
      value: singleJob.customerAddress,
    },
    {
      label: "imei",
      value: singleJob.customer_imei,
    },
  ];
  const repairShopInfo = [
    {
      label: "Name der Werkstatt",
      value: singleJob.repairShopName,
    },
    {
      label: "E-Mail-Addresse",
      value: singleJob.repairShopAddress,
    },
    {
      label: "Telefonnummer",
      value: singleJob.repairShopPhone,
    },
    {
      label: " Adresse",
      value: singleJob.repairShopAddress,
    },
  ];
  const otherInfo = [
    {
      label: "Hersteller",
      value: singleJob.manufacturer,
    },
    {
      label: "Modell",
      value: singleJob.model,
    },
    {
      label: "Modellpreis",
      value: singleJob.modelPrice + " €",
    },
    {
      label: "Schaden",
      value: singleJob.damageParts,
    },
    {
      label: "Plan Price",
      value: singleJob.planPrice + " €",
    },
  ];

  const handleBill = (event, row) => {
    event.stopPropagation();
    console.log("fefsdf", row.imageUrl);
    window.open(row.imageUrl, "_blank");
  };

  return (
    <div>
      <Loader />
      <div>
        <br />
        <br />
        <NavLink
          to="/dashboard"
          className="px-5 bg-black text-white w-20 text-center py-2 rounded"
        >
          zurück
        </NavLink>
        {/* dailogue  start */}
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Berufsinformation
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Präsentieren Sie sowohl die Kunden- als auch die
                Shop-Informationen für einen bestimmten Job auf klare und
                prägnante Weise.
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "right",
                  margin: "10px 0px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {/* Job Status : &nbsp;&nbsp;
                {singleJob.jobStatus.toUpperCase() === "COMPLETED"
                  ? "Erledigt"
                  : singleJob.jobStatus.toUpperCase() === "PENDING"
                  ? "In Bearbeitung"
                  : singleJob.jobStatus.toUpperCase() === "APPROVED"
                  ? "Bestätigt"
                  : singleJob.jobStatus.toUpperCase()} */}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "20px 0px",
                }}
              >
                Kundeninformation :
              </Typography>

              <Typography
                gutterBottom
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {customerInfo.map((elem, index) => {
                  return (
                    <Typography className="flex gap-5" key={index}>
                      <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                        {elem.label} :
                      </Typography>
                      <Typography gutterBottom>{elem.value}</Typography>
                    </Typography>
                  );
                })}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "20px 0px",
                }}
              >
                Informationen zur Werkstatt :
              </Typography>
              <Typography
                gutterBottom
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {repairShopInfo.map((elem, index) => {
                  return (
                    <Typography className="flex gap-5" key={index}>
                      <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                        {elem.label} :
                      </Typography>
                      <Typography gutterBottom>{elem.value}</Typography>
                    </Typography>
                  );
                })}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "20px 0px",
                }}
              >
                Andere Information :
              </Typography>
              <Typography
                gutterBottom
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {otherInfo.map((elem, index) => {
                  return (
                    <Typography className="flex gap-5" key={index}>
                      <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                        {elem.label} :
                      </Typography>
                      <Typography gutterBottom>{elem.value}</Typography>
                    </Typography>
                  );
                })}
              </Typography>
            </DialogContent>
          </BootstrapDialog>
        </div>
        {/* dailogue   end */}
        {/* <!--Tabs navigation--> */}
        <ul
          className="mt-5 mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
          role="tablist"
          data-te-nav-ref
        >
          <li role="presentation">
            <a
              href="#tabs-home"
              className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-bold  leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-home"
              data-te-nav-active
              role="tab"
              aria-controls="tabs-home"
              aria-selected="true"
            >
              Aufträge
            </a>
          </li>
          <li role="presentation">
            <a
              href="#tabs-profile"
              className="focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-bold  leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-profile"
              role="tab"
              aria-controls="tabs-profile"
              aria-selected="false"
            >
              Angefragt
            </a>
          </li>
        </ul>

        {/* <!--Tabs content--> */}
        <div className="mb-6">
          <div
            className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-home"
            role="tabpanel"
            aria-labelledby="tabs-home-tab"
            data-te-tab-active
          >
            <Paper sx={{ overflow: "hidden", mt: "50px" }}>
              <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "bold" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            onClick={() => {
                              handleClickOpen(row);
                            }}
                          >
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.status.toUpperCase() === "COMPLETED" ? (
                                <span className="flex items-center gap-3">
                                  <span>Erledigt</span>
                                  <span
                                    className="bg-slate-200 p-2 rounded cursor-pointer"
                                    onClick={(e) => {
                                      handleBill(e, row);
                                    }}
                                  >
                                    Rechnung ansehen
                                  </span>
                                </span>
                              ) : row.status.toUpperCase() === "PENDING" ? (
                                "In Bearbeitung"
                              ) : row.status.toUpperCase() === "APPROVED" ? (
                                "Bestätigt"
                              ) : (
                                row.status.toUpperCase()
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan[0].shop.length > 0 ? (
                                  row.plan[0].shop[0].companyName
                                ) : (
                                  <span>Gelöscht</span>
                                )
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan[0].shop.length > 0 ? (
                                  row.plan[0].shop[0].zipCode +
                                  " - " +
                                  row.plan[0].shop[0].ort +
                                  " - " +
                                  row.plan[0].shop[0].streetAddress +
                                  " " +
                                  row.plan[0].shop[0].houseNumber
                                ) : (
                                  <span>Gelöscht</span>
                                )
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.emailAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.streetNumber} , {row.postalCodeCity}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan[0].manufacturer[0].name
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan[0].manufacturer[0].models[0].modalName
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan.length > 1 ? (
                                  row.plan.map(
                                    (el) => el.damagePart[0].damagePart + ", "
                                  )
                                ) : (
                                  row.plan[0].damagePart[0].damagePart
                                )
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  row.plan.length > 0
                                    ? row.plan[0].shop.length > 0
                                      ? "gray"
                                      : "red"
                                    : "red",
                              }}
                            >
                              {row.plan.length > 0 ? (
                                row.plan.length > 1 ? (
                                  <span>
                                    {calDoublePlanPriceTemp(row.plan)} &euro;
                                  </span>
                                ) : (
                                  <span>{row.plan[0].price} &euro;</span>
                                )
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={claimList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
          <div
            className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-profile"
            role="tabpanel"
            aria-labelledby="tabs-profile-tab"
          >
            <Paper sx={{ overflow: "hidden", mt: "50px" }}>
              <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          sx={{ fontWeight: "bold" }}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestedClaimList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.status.toUpperCase() === "COMPLETED"
                                ? "Erledigt"
                                : row.status.toUpperCase() === "PENDING"
                                ? "In Bearbeitung"
                                : row.status.toUpperCase()}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.shop.length > 0 ? (
                                row.shop[0].companyName
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.shop.length > 0 ? (
                                row.shop[0].zipCode +
                                " - " +
                                row.shop[0].ort +
                                " - " +
                                row.shop[0].streetAddress +
                                " " +
                                row.shop[0].houseNumber
                              ) : (
                                <span>Gelöscht</span>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.emailAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.streetNumber} , {row.postalCodeCity}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.phoneManufacture}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.phoneModal}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.damages.map((el) => el + ", ")}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: row.shop.length > 0 ? "gray" : "red",
                              }}
                            >
                              {row.price && row.status === "pending" ? (
                                <Button
                                  onClick={() => {
                                    handleUpdateStatus(row, "approved");
                                  }}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>Approve {row.price} &euro;</span>
                                </Button>
                              ) : row.price ? (
                                <span>{row.price} &euro;</span>
                              ) : (
                                "Preis angefragt"
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={requestedClaimList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Jobs;

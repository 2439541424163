import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { serverHost } from "../config";
import { useStateContext } from "../contexts/ContextProvider";

const UserNavbar = () => {
  const {
    dynamicUserName,
    setDynamicUserName,
    setDynamicUserId,
    setDynamicUserData,
    dynamicUserData,
  } = useStateContext();

  useEffect(() => {
    const authToken = localStorage.getItem("auth-token");
    fetch(`${serverHost}/api/ppvs/getuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        setDynamicUserData(res.insuranceCompany);
        setDynamicUserName(res.insuranceCompany.emailAddress.split("@")[0]);
        setDynamicUserId(res.insuranceCompany._id);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [setDynamicUserName, setDynamicUserId, setDynamicUserData]);

  return (
    <>
      <div className="h-28 border-b border-gray-300 flex items-center justify-center lg:justify-between lg:flex-row flex-col gap-3">
        <div className="uppercase text-2xl sm:text-4xl text-[#45afe3] font-extrabold">
          Priceparent.de
        </div>
        <div className="flex items-center justify-center gap-3 sm:gap-10 md:gap-20 lg:gap-5 text-gray-500 text-sm">
          <div>
            Sie sind angemeldet als:
            <b> {dynamicUserName}</b>
          </div>

          <div class="dropdown">
            <span>
              <img
                src={
                  dynamicUserData.imageUrl
                    ? dynamicUserData.imageUrl
                    : "https://cdn-icons-png.flaticon.com/512/61/61205.png"
                }
                alt=""
                className="img-fluid w-12 h-12   rounded-full"
              />
            </span>
            <div class="dropdown-content">
              <p className="my-3  py-2">
                <NavLink
                  to="/myaccount"
                  className="w-full py-2 px-3 hover:bg-slate-200 cursor-pointer rounded"
                >
                  Mein Konto
                </NavLink>
              </p>

              <p className="my-3  py-2">
                <NavLink
                  to="/myjobs"
                  className="py-2 px-3 hover:bg-slate-200 cursor-pointer rounded"
                >
                  Meine Aufträge
                </NavLink>
              </p>
              <p className="my-3  py-2">
                <p
                  className="py-2 px-3 hover:bg-red-200 cursor-pointer rounded text-red-500 "
                  onClick={() => {
                    localStorage.removeItem("auth-token");
                    window.location = "/";
                  }}
                >
                  Logout
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNavbar;

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const ErrorMessage = (msg) => {
  toast(msg, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

// export const serverHost = "https://insurancerepair.netlify.app";
export const serverHost = "https://ppvsapi.netlify.app";
// export const serverHost = "http://localhost:8888";

//dashboard

export const dashboardFormClasses = {
  rows: "flex sm:justify-between sm:items-center sm:flex-row flex-col gap-2",
  dropdownList:
    "dropdown-toggle px-6 py-2.5 bg-slate-600 text-white font-medium text-xs leading-tight uppercase shadow-xl hover:bg-slate-800 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap   justify-between sm:w-96 w-full cursor-pointer truncate",
  ul_tag_classes:
    "dropdown-menu w-full  min-w-max absolute z-[1000] hidden text-base  float-left py-2 list-none text-left shadow-lg mt-1 hidden m-0 bg-clip-padding border-none bg-gradient-to-b from-slate-400 to-slate-500   [&[data-te-dropdown-show]]:block",
  li_tag_classes:
    "dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-slate-400 cursor-pointer",
  svgIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="h-5 w-5"
    >
      <path
        fillRule="evenodd"
        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
        clipRule="evenodd"
      />
    </svg>
  ),
  allFormsHeadings:
    "h-20 border-b text-xl sm:text-3xl font-bold uppercase tracking-[3px] w-full flex items-center",
};
export const isNotEmptyObj = (obj) => {
  return Object.values(obj).every((val) => val);
};

export const isEmptycheckFormOneInputs = (obj) => {
  if (
    obj.damage.length &&
    obj.manufacturer !== "" &&
    obj.model !== "" &&
    obj.ort !== ""
  ) {
    return true;
  } else return false;
};

// dashboard from 2
export const suggestedWorkshops = [
  {
    workshopName: "fair deals",
    image:
      "https://images.unsplash.com/photo-1532522750741-628fde798c73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "65",
  },
  {
    workshopName: "minor deals",
    image: "https://wallpaperaccess.com/full/302495.jpg",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "56",
  },
  {
    workshopName: "vipe deals",
    image:
      "https://images.unsplash.com/photo-1532522750741-628fde798c73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "54",
  },
  {
    workshopName: "chain deals",
    image:
      "https://png.pngtree.com/thumb_back/fh260/background/20210913/pngtree-cloud-computing-technology-background-image_877094.jpg",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "90",
  },
];

export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9" },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630" },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

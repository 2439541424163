import { useEffect, useState } from "react";
import {
  dashboardFormClasses,
  isEmptycheckFormOneInputs,
  serverHost,
  // colourOptions,
} from "../config";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const Form1 = () => {
  //

  const [manufacturerArray, setManufacturerArray] = useState([]);
  const [locationsArray, setLocationsArray] = useState([]);
  const [modelsArray, setModelsArray] = useState([]);
  // const [dataIsGet, setDataIsGet] = useState(false);

  //
  const [manufacturers, setManufacturers] = useState([]);
  const [modalNames, setModelNames] = useState([]);
  const [shopNames, setShopNames] = useState([]);
  const [damagePartList, setDamagePartList] = useState([]);
  const [isPhoneCheck, setIsPhoneCheck] = useState(false);
  const {
    rows,
    dropdownList,
    ul_tag_classes,
    li_tag_classes,
    svgIcon,
    allFormsHeadings,
  } = dashboardFormClasses;
  const { dashboardForm, setDashboardForm, setDashboardCurrentComp } =
    useStateContext();
  const continueForm1 = () => {
    if (isEmptycheckFormOneInputs(dashboardForm)) {
      setDashboardCurrentComp("form2");
    } else {
      setDashboardCurrentComp("form1");
    }
  };
  const reactSelectDamageStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#475569",
      color: "white",
      width: "24rem",
      margin: "0px",
      // width: "380px",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#475569",
      color: "white",
      cursor: "pointer",
      margin: "0px",
      ":hover": {
        backgroundColor: "rgba(51,65,85,255)",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    setManufacturerArray([]);
    setModelsArray([]);
    fetch(`${serverHost}/api/admin/all/manufactures`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        setManufacturers(res.munufactureList);
        for (var a = 0; a < res.munufactureList.length; a++) {
          const { name, _id } = res.munufactureList[a];
          const newObj = { label: name, value: _id };
          setManufacturerArray((prevArray) => [...prevArray, newObj]);
          console.log({ hello: "test " + a });
        }
      })
      .catch((err) => {
        console.log({ err });
      });
    fetch(`${serverHost}/api/admin/all/damageparts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        const options = [];
        res.damagepartsList.forEach((element) => {
          options.push({ value: element._id, label: element.damagePart });
        });
        setDamagePartList(options);
      })
      .catch((err) => {
        console.log({ err });
      });
    const name = dashboardForm.manufacturerName;
    console.log({ name });
    const data = { name };
    dashboardForm.manufacturer &&
      fetch(`${serverHost}/api/admin/manufacturer/models`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((models) => models.json())
        .then((models) => {
          console.log({ models: models.modelList.models });
          setModelNames(models.modelList.models);

          for (var a = 0; a < models.modelList.models.length; a++) {
            const { modalName, modalPrice, _id } = models.modelList.models[a];
            const newObj = { label: modalName, value: _id, price: modalPrice };
            console.log({ newObj });
            setModelsArray((prevArray) => [...prevArray, newObj]);
            console.log({ modelsArray });
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    fetch(`${serverHost}/api/admin/get/repairshops`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // setShopNames(res.repairShops);
        let array = res.repairShops;
        // let filteredArray = [];
        // for (var a = 0; a < array.length; a++) {
        //   console.log(array[a].ort);

        //   if (!filteredArray.includes(array[a].ort)) {
        //     filteredArray.push(array[a].ort);
        //   }
        // }
        // setShopNames(filteredArray);
        // console.log({ filteredArray });

        for (let i = 0; i < array.length; i++) {
          const labelExists = locationsArray.some(
            (item) => item.label === array[i].ort
          );
          console.log({ labelExists });
          if (!labelExists) {
            locationsArray.push({
              label: array[i].ort,
              value: array[i].ort,
            });
          }
        }
        console.log({ locationsArray });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [dashboardForm.manufacturer, dashboardForm.manufacturerName]);

  return (
    <>
      <Loader />
      <div className="p-5 text-white my-5 bg-slate-700 h-[750px] rounded-lg form1">
        <div className={allFormsHeadings}>neuer auftrag</div>
        <div className="flex flex-col gap-10 sm:gap-16 my-20  sm:w-[95%] md:w-[92%] lg:w-[80%] xl:w-[60%] 2xl:w-[50%]  m-auto">
          <div className={rows}>
            <div className="w-48 uppercase font-bold sm:text-right">
              hersteller :
            </div>
            {!isPhoneCheck ? (
              <Select
                className="basic-single text-white border-white outline-none shadow-none  "
                classNamePrefix="select"
                isSearchable={true}
                name="colors"
                onChange={(e) => {
                  setDashboardForm({
                    ...dashboardForm,
                    manufacturerName: e.label,
                    manufacturer: e.value,
                    modelName: "",
                    model: "",
                  });

                  // setManufacturerArray([]);
                }}
                options={manufacturerArray}
                styles={reactSelectDamageStyle}
                placeholder="Auswählen"
              />
            ) : (
              <div>
                <div
                  className="relative w-full sm:w-96"
                  data-te-input-wrapper-init
                >
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-0 bg-white px-3 py-[0.32rem] leading-[1.6] outline-none text-black"
                    id="exampleFormControlInput1"
                    placeholder="Geben Sie den Telefonhersteller ein"
                    onChange={(e) => {
                      setDashboardForm({
                        ...dashboardForm,
                        manufacturerName: e.target.value,
                        manufacturer: e.target.value,
                        model: "",
                        modelName: "",
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={rows}>
            <div className="w-48 uppercase font-bold sm:text-right">
              modell :
            </div>
            {!isPhoneCheck ? (
              <div className="modelsClass">
                <Select
                  className="basic-single text-white border-white outline-none shadow-none"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="colors"
                  onChange={(e) => {
                    setDashboardForm({
                      ...dashboardForm,
                      modelName: e.label,
                      model: e.value,
                      modelPrice: e.price,
                    });
                    // setModelsArray([]);
                  }}
                  options={modelsArray}
                  styles={reactSelectDamageStyle}
                  placeholder="Auswählen"
                />
              </div>
            ) : (
              <div>
                <div
                  className="relative w-full sm:w-96 "
                  data-te-input-wrapper-init
                >
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-0 bg-white px-3 py-[0.32rem] leading-[1.6] outline-none text-black"
                    id="exampleFormControlInput1"
                    placeholder="Telefonmodell eingeben"
                    onChange={(e) => {
                      setDashboardForm({
                        ...dashboardForm,
                        modelName: e.target.value,
                        model: e.target.value,
                        modelPrice: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={rows}>
            <div className="w-48 uppercase font-bold sm:text-right">
              schaden :
            </div>
            <div>
              {!isPhoneCheck ? (
                <div>
                  <Select
                    isMulti
                    options={damagePartList}
                    onChange={(e) => {
                      setDashboardForm({
                        ...dashboardForm,
                        damage: e,
                      });
                    }}
                    styles={reactSelectDamageStyle}
                    placeholder="Auswählen"
                  />
                </div>
              ) : (
                <div>
                  <CreatableSelect
                    isMulti
                    options={damagePartList}
                    onChange={(e) => {
                      setDashboardForm({
                        ...dashboardForm,
                        damage: e,
                      });
                    }}
                    styles={reactSelectDamageStyle}
                    placeholder="Auswählen"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={rows}>
            <div className="w-48 uppercase font-bold sm:text-right">ort : </div>
            <div>
              <Select
                className="basic-single text-white border-white outline-none shadow-none  "
                classNamePrefix="select"
                isSearchable={true}
                onChange={(e) => {
                  setDashboardForm({
                    ...dashboardForm,
                    ort: e.label,
                  });
                }}
                options={locationsArray}
                styles={reactSelectDamageStyle}
                placeholder="Auswählen"
              />
            </div>
          </div>
          <div className="text-center">
            <input
              type="checkbox"
              id="phone-check"
              onChange={() => {
                setIsPhoneCheck(!isPhoneCheck);
                setDashboardForm({
                  ...dashboardForm,
                  isPhoneNew: !isPhoneCheck,
                  modelName: "",
                  model: "",
                  modelPrice: "",
                  manufacturerName: "",
                  manufacturer: "",
                });
              }}
            />
            <label htmlFor="phone-check" className="ml-2">
              Sie haben Ihr Telefon nicht gefunden?
            </label>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex-1 text-right mb-5">
              <span
                className="bg-slate-600  hover:bg-slate-800  px-5 py-2 shadow-xl text-sm rounded cursor-pointer"
                onClick={continueForm1}
              >
                WEITER
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form1;

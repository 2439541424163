import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import LoginResetPasswordForm from "../components/LoginResetPasswordForm";
import Navbar from "../components/Navbar";

const Login = () => {
  const [resetPasswordActiveSate, setResetPasswordActiveState] =
    useState(false);

  useEffect(() => {
    if (window.location.href.includes("reset-password"))
      setResetPasswordActiveState(true);
    else setResetPasswordActiveState(false);
  }, []);

  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <Navbar />
      {resetPasswordActiveSate ? <LoginResetPasswordForm /> : <LoginForm />}
      <Footer />
    </div>
  );
};

export default Login;

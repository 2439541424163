import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import Myjobs from "./pages/Myjobs";
import ProtectedRoutes from "./ProtectedRoutes";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/dashboard"
          element={<ProtectedRoutes Component={Dashboard} />}
        />
        <Route path="/login" element={<ProtectedRoutes Component={Login} />} />
        <Route path="/login/reset-password" element={<Login />} />
        <Route
          path="/myjobs"
          element={<ProtectedRoutes Component={Myjobs} />}
        />
        <Route
          path="/myaccount"
          element={<ProtectedRoutes Component={MyAccount} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

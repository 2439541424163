import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useStateContext } from "../contexts/ContextProvider";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { ErrorMessage, serverHost } from "../config";
import Loader from "./Loader";
const LoginForm = () => {
  const [userEmail, setUserEmail] = useState("");
  const [resetUserPassword, setResetUserPassword] = useState("");
  const [resetPasswordConfirm, setResetPasswordConfirm] = useState("");
  const [resetPasswordView, setResetPasswordView] = useState(false);

  const { setLoader } = useStateContext();

  const resetPassword = () => {
    if (
      userEmail === "" ||
      resetPasswordConfirm === "" ||
      resetUserPassword === ""
    ) {
      ErrorMessage("Bitte füllen Sie alle Felder aus");
    } else if (resetPasswordConfirm === resetUserPassword) {
      setLoader(true);
      const data = {
        userEmail,
        userID: window.location.search.split("?id=")[1].trim(),
        newPassword: resetPasswordConfirm,
      };
      fetch(`${serverHost}/api/login/reset-password/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((login) => login.json())
        .then((login) => {
          setLoader(false);
          if (login.error) {
            ErrorMessage(login.error);
          } else if (login.errors) {
            ErrorMessage(login.errors[0].msg);
          } else {
            ErrorMessage(login.success);
            window.location = login.redirect;
          }
        })
        .catch((login_err) => {
          setLoader(false);
          console.log({ login_err });
        });
    } else ErrorMessage("Das Passwort stimmt nicht überein");
  };
  return (
    <>
      <Loader></Loader>
      <div className="main-header-image py-5 text-white my-5 bg-[#45afe3] rounded-lg flex flex-col items-center  h-[700px] justify-evenly gap-5">
        {/* <img src={logo} alt="im" className="h-28" /> */}
        <div className="uppercase md:text-[40px] sm:text-2xl text-xl font-bold ">
          Passwort zurücksetzen
        </div>
        <div className="flex flex-col gap-4 items-center w-full">
          <div className="xl:w-[40%] lg:w-[60%] md:w-[65%] sm:w-[70%] w-[80%]">
            <div className="flex  my-3">
              <input
                type="text"
                name="username"
                id="email"
                className="border-none px-2 w-full text-gray-500 outline-none shadow-none h-10"
                placeholder="Einschreiben eingeben"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value.toLowerCase());
                }}
              />
            </div>
            <div className="flex px-2 bg-white my-3 h-10 items-center gap-2">
              <input
                type={resetPasswordView ? "text" : "password"}
                name="password"
                className="border-none  flex-1 outline-none shadow-none h-full text-gray-500"
                placeholder="Neues Kennwort"
                value={resetUserPassword}
                onChange={(e) => {
                  setResetUserPassword(e.target.value);
                }}
              />
              {resetPasswordView ? (
                <VisibilityOffIcon
                  className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                  onClick={() => setResetPasswordView(false)}
                />
              ) : (
                <RemoveRedEyeIcon
                  className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                  onClick={() => setResetPasswordView(true)}
                />
              )}
            </div>
            <div className="flex px-2 bg-white   my-3 h-10 items-center gap-2">
              <input
                type={resetPasswordView ? "text" : "password"}
                name="password"
                className="border-none  flex-1 outline-none shadow-none h-full text-gray-500"
                placeholder="Bestätige das Passwort"
                value={resetPasswordConfirm}
                onChange={(e) => {
                  setResetPasswordConfirm(e.target.value);
                }}
              />
              {resetPasswordView ? (
                <VisibilityOffIcon
                  className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                  onClick={() => setResetPasswordView(false)}
                />
              ) : (
                <RemoveRedEyeIcon
                  className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                  onClick={() => setResetPasswordView(true)}
                />
              )}
            </div>
          </div>

          <div
            className="cursor-pointer border border-white px-3 py-2 rounded-lg font-bold text-center mt-5"
            onClick={resetPassword}
          >
            Kennwort ändern
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default LoginForm;

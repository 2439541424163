import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
// import logo from "../assets/images/logo2.jpg";
import { useStateContext } from "../contexts/ContextProvider";
import { ToastContainer } from "react-toastify";
import { ErrorMessage, serverHost } from "../config";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
const LoginForm = () => {
  const [resetPasswordActive, setResetPasswordActive] = useState(false);

  const navigate = useNavigate();
  const {
    viewPassword,
    setViewPassword,
    usernameLogin,
    setUsernameLogin,
    passwordLogin,
    setPasswordLogin,
    setLoader,
  } = useStateContext();
  const loginUser = () => {
    if (usernameLogin === "" || passwordLogin === "") {
      ErrorMessage("Bitte füllen Sie alle Felder aus");
    } else {
      const data = { usernameLogin, passwordLogin };
      fetch(`${serverHost}/api/ppvs/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((login) => login.json())
        .then((login) => {
          console.log({ login });
          if (login.error) {
            ErrorMessage(login.error);
          } else if (login.errors) {
            ErrorMessage(login.errors[0].msg);
          } else {
            localStorage.setItem("auth-token", login.authtoken);
            navigate("/dashboard");
          }
        })
        .catch((login_err) => {
          console.log({ login_err });
        });
    }
  };
  const resetPassword = () => {
    if (usernameLogin === "") {
      ErrorMessage("Bitte füllen Sie alle Felder aus");
    } else {
      setLoader(true);
      const data = {
        usernameLogin,
        redirectURL: window.location.href,
        platform: "ppvs",
      };
      fetch(`${serverHost}/api/login/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((login) => login.json())
        .then((login) => {
          setLoader(false);
          if (login.error) {
            ErrorMessage(login.error);
          } else if (login.errors) {
            ErrorMessage(login.errors[0].msg);
          } else {
            ErrorMessage(login.success);
          }
        })
        .catch((login_err) => {
          setLoader(false);
          console.log({ login_err });
        });
    }
  };
  return (
    <>
      <Loader></Loader>
      <div className="main-header-image py-5 text-white my-5 bg-[#45afe3] rounded-lg flex flex-col items-center  h-[700px] justify-evenly gap-5">
        {/* <img src={logo} alt="im" className="h-28" /> */}
        <div className="uppercase md:text-[40px] sm:text-2xl text-xl font-bold ">
          Hier anmelden: (login)
        </div>
        <div className="flex flex-col gap-4 items-center w-full">
          <div className="h-24  xl:w-[40%] lg:w-[60%] md:w-[65%] sm:w-[70%] w-[80%]">
            <div className="flex  my-3">
              <input
                type="text"
                name="username"
                id="email"
                className="border-none px-2 w-full text-gray-500 outline-none shadow-none h-10"
                placeholder="Nutzername"
                value={usernameLogin}
                onChange={(e) => {
                  setUsernameLogin(e.target.value.toLowerCase());
                }}
              />
            </div>
            {!resetPasswordActive ? (
              <div className="flex px-2 bg-white   my-3 h-10 items-center gap-2">
                <input
                  type={viewPassword ? "text" : "password"}
                  name="password"
                  className="border-none  flex-1 outline-none shadow-none h-full text-gray-500"
                  placeholder="Passwort"
                  value={passwordLogin}
                  onChange={(e) => {
                    setPasswordLogin(e.target.value);
                  }}
                />
                {viewPassword ? (
                  <VisibilityOffIcon
                    className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                    onClick={() => setViewPassword(false)}
                  />
                ) : (
                  <RemoveRedEyeIcon
                    className="text-gray-500 cursor-pointer border-l border-gray-500 pl-1"
                    onClick={() => setViewPassword(true)}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {!resetPasswordActive ? (
            <>
              <div
                className="cursor-pointer border border-white px-3 py-2 rounded-lg font-bold w-32 text-center mt-5"
                onClick={loginUser}
              >
                Anmelden
              </div>
              <div
                className="cursor-pointer underline underline-offset-8  text-center"
                onClick={() => setResetPasswordActive(!resetPasswordActive)}
              >
                Passwort zurücksetzen
              </div>
            </>
          ) : (
            <>
              <div
                className="cursor-pointer border border-white px-3 py-2 rounded-lg font-bold text-center mt-5"
                onClick={resetPassword}
              >
                Passwort zurücksetzen
              </div>
              <div
                className="cursor-pointer underline underline-offset-8 text-center"
                onClick={() => setResetPasswordActive(!resetPasswordActive)}
              >
                Anmelden
              </div>
            </>
          )}
        </div>

        {/* <div className="flex items-center justify-between md:flex-row flex-col gap-3 text-lg font-bold">
          <div className="cursor-pointer">Noch kein Zugang?</div>
          <div className="cursor-pointer">Passwort vergessen?</div>
          <div className="cursor-pointer ">Aktivierungs-Emails fehit?</div>
        </div> */}
      </div>

      <ToastContainer />
    </>
  );
};

export default LoginForm;

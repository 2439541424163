import { useState } from "react";
import { dashboardFormClasses, ErrorMessage, serverHost } from "../config";
import { useStateContext } from "../contexts/ContextProvider";
import { ToastContainer } from "react-toastify";
import Loader from "./Loader";
// eslint-disable-next-line
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet, // eslint-disable-next-line
  // PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const Form3 = () => {
  const {
    dashboardForm,
    dashboardForm2,
    inputs,
    setInputs,
    setDashboardCurrentComp, // eslint-disable-next-line
    loader,
    setLoader,
    dynamicUserData,
  } = useStateContext();
  const [pdfDownloadReady, setPdfDownloadReady] = useState(false);
  const [claimID, setClaimID] = useState("");
  const { allFormsHeadings, rows } = dashboardFormClasses;

  const handleInputChange = (event, index) => {
    const updatedInputs = [...inputs];
    updatedInputs[index].value = event.target.value;
    setInputs(updatedInputs);
  };
  const generatePDF = () => {
    const isAllFilled = inputs.every((obj) =>
      Object.keys(obj).every((key) => obj[key])
    );

    console.log({ isAllFilled });
    if (isAllFilled === false) {
      ErrorMessage("please fill all the fields");
    } else {
      setLoader(true);
      const planId = dashboardForm2.planId;
      const data = { planId, inputs };
      if (dashboardForm.isPhoneNew) {
        data.shopID = planId;
        data.phoneManufacture = dashboardForm.manufacturerName;
        data.phoneModal = dashboardForm.modelName;
        data.damages = [];
        dashboardForm.damage.forEach((element) => {
          data.damages.push(element.label);
        });
        data.workshopEmail = dashboardForm2.workshopEmail;
        delete data.planId;
      } else {
        data.dashboardForm2 = dashboardForm2;
        data.dashboardForm = dashboardForm;
      }
      const authToken = localStorage.getItem("auth-token");

      fetch(
        dashboardForm.isPhoneNew
          ? `${serverHost}/api/ppvs/insuranceclaim/request/new`
          : `${serverHost}/api/ppvs/insuranceclaim/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setLoader(false);
          console.log({ response });
          if (response.success === true) {
            setClaimID(response.claimID);
            setPdfDownloadReady(true);
            ErrorMessage(response.message);
          }
          if (response.success === false) {
            ErrorMessage(response.message);
          }
          if (response.success === "tokenError") {
            ErrorMessage(response.error);
          }
          if (response.errors) {
            ErrorMessage(response.errors[0].msg);
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      fontSize: "15px",
      position: "relative",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    image: {
      width: "150px",
    },
    imageView: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    infoValue: {
      textDecoration: "underline",
      textDecorationColor: "#45afe3",
      paddingBottom: 10,
      textDecorationStyle: "double",
    },
    infoLabel: {
      fontSize: "10px",
    },
    mainView: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
    mainViewItem: {
      flex: "1 0 30%",
      margin: "5px",
      marginBottom: "30px",
    },
    topBar: {
      backgroundColor: "#525659",
      height: "50px",
    },
    bottomBar: {
      backgroundColor: "#45afe3",
      height: "50px",
      width: "100%",
      position: "absolute",
      bottom: 0,
    },
  });

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.topBar}></View>
        <View style={[styles.imageView, styles.section]}>
          <View>
            <Text style={styles.infoValue}>{claimID}</Text>
            <Text style={styles.infoLabel}>VERSICHERUNGS NR</Text>
          </View>
          {dynamicUserData.imageUrl ? <Image style={styles.image} src={dynamicUserData.imageUrl}></Image> : ""}
        </View>
        <View style={[styles.section, styles.mainView]}>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>{inputs[1].value}</Text>
            <Text style={styles.infoLabel}>NACHNAME</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>{inputs[1].value}</Text>
            <Text style={styles.infoLabel}>NACHNAME</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>
              {dashboardForm.damage.map((elem) => elem.label + ", ")}
            </Text>
            <Text style={styles.infoLabel}>SCHÄDEN</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>{inputs[0].value}</Text>
            <Text style={styles.infoLabel}>VORNAME</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>{dashboardForm2.workshopName}</Text>
            <Text style={styles.infoLabel}>REPARATUR-ID</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>{dashboardForm2.price} &euro;</Text>
            <Text style={styles.infoLabel}>PREIS</Text>
          </View>
          <View style={[styles.mainViewItem, { marginTop: "100px" }]}>
            <Text style={styles.infoValue}>__________________</Text>
            <Text style={styles.infoLabel}>ANMERKUNG / SONSTIGES</Text>
          </View>
          <View style={styles.mainViewItem}>
            <Text style={styles.infoValue}>__________________</Text>
            <Text style={styles.infoLabel}>DATUMSSTEMPEL</Text>
          </View>
        </View>
        <View style={styles.bottomBar}></View>
      </Page>
    </Document>
  );

  return (
    <>
      <Loader />

      <div className="p-5 text-white my-5 bg-slate-700 min-h-[700px] rounded-lg">
        <div className={allFormsHeadings}>reparaturschein generieren</div>
        <div className="flex flex-wrap items-center gap-5 my-5 ">
          <div className="font-bold">Ihr Auftrag: </div>
          <div>{dashboardForm.manufacturerName} | </div>
          <div>{dashboardForm.modelName} | </div>
          <div>{dashboardForm.damage.map((elem, index) => { return elem.label + ((index === dashboardForm.damage.length - 1) ? "" : ", ") })} | </div>
          <div>{dashboardForm.ort} | </div>
          <div>Werkstatt : {dashboardForm2.workshopName} |</div>
          {!dashboardForm.isPhoneNew ? (
            <div>Preis : {dashboardForm2.price}, -&euro;</div>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-col gap-3 sm:gap-10 my-20  sm:w-[95%] md:w-[95%] lg:w-[80%] xl:w-[60%] 2xl:w-[45%]  m-auto">
          {inputs.map((input, index) => (
            <div className={rows} key={index}>
              <div className="w-40 uppercase-a font-bold sm:text-right ">
                {input.title} * :
              </div>
              <div>
                <input
                  type="text"
                  className="border-none px-2 w-full text-gray-500 outline-none shadow-none h-10 sm:w-96 w-full"
                  value={input.value}
                  onChange={(event) => handleInputChange(event, index)}
                />
              </div>
            </div>
          ))}

          <div
            className="uppercase border border-slate-400 w-54 ml-auto px-2 py-2 text-center rounded cursor-pointer"
            onClick={generatePDF}
          >
            pdf generieren
          </div>
          {pdfDownloadReady ? (
            <PDFDownloadLink
              className="uppercase border border-slate-400 w-54 ml-auto px-2 py-2 text-center rounded cursor-pointer"
              document={<MyDocument />}
              fileName="Ihr Reparaturschein.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
          ) : (
            ``
          )}
        </div>
        <div
          className="border border-white w-20 text-center py-1 rounded cursor-pointer"
          onClick={() => {
            setDashboardCurrentComp("form2");
          }}
        >
          zurück
        </div>
      </div>
      {/* <PDFViewer
        style={{ width: "100%", height: "700px", marginBottom: "200px" }}
      >
        <MyDocument />
      </PDFViewer> */}
      <ToastContainer />
    </>
  );
};

export default Form3;

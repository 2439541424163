import { useEffect, useState } from "react";
import { dashboardFormClasses, serverHost } from "../config";
import { useStateContext } from "../contexts/ContextProvider";
const Form2 = () => {
  const [shopNames, setShopNames] = useState([]);
  const {
    dashboardForm,
    setDashboardCurrentComp,
    setDashboardForm2,
    setDashboardForm,
  } = useStateContext();
  const { allFormsHeadings } = dashboardFormClasses;
  const {
    manufacturer,
    model,
    damage,
    ort,
    modelName,
    manufacturerName,
    modelPrice,
    isPhoneNew,
  } = dashboardForm;
  useEffect(() => {
    const damagePart = damage;
    const address = ort;
    const data = {
      address,
      manufacturer,
      model,
      damagePart,
      isPhoneNew,
    };
    fetch(
      isPhoneNew
        ? `${serverHost}/api/admin/repairplans/list/ort`
        : `${serverHost}/api/admin/repairplans/list`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ form2: res });
        setShopNames(res.finalPlans);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [damage, manufacturer, model, ort, isPhoneNew]);

  return (
    <>
      <div className="p-5 text-white my-5 bg-slate-700 min-h-[700px] rounded-lg">
        <div className={allFormsHeadings}>preisauskunft</div>
        <div className="flex flex-wrap items-center gap-5 my-5">
          <div className="font-bold">Ihr Auftrag: </div>
          <div>{manufacturerName} | </div>
          <div>{modelName} | </div>
          <div>{damage.map((elem, index) => { return elem.label + ((index === damage.length - 1) ? "" : ", ") })} | </div>
          <div>{ort} </div>
          {!isPhoneNew ? (
            <div className="ml-auto">
              <strong>Zeitwert: {modelPrice} &euro;</strong>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="my-10  gap-10 grid  xl:grid-cols-4 sm:grid-cols-2 grid-cols-1">
          {shopNames.map((elem, index) => {
            if (isPhoneNew) elem = { repairShop: elem };
            return (
              <div key={index}>
                <div className="bg-slate-600 h-80 p-3 rounded-lg">
                  <img
                    src={
                      elem.repairShop.imageUrl
                        ? elem.repairShop.imageUrl
                        : `https://aeroclub-issoire.fr/wp-content/uploads/2020/05/image-not-found.jpg`
                    }
                    alt=""
                    className="w-full rounded-lg h-2/4 mx-auto"
                  />
                  <div className="h-2/4 flex justify-center flex-col gap-5">
                    <div className=" h-3/4 overflow-hidden flex items-center">
                      {`${elem.repairShop.streetAddress} ${elem.repairShop.houseNumber} ${elem.repairShop.zipCode} ${elem.repairShop.ort}`}
                    </div>
                    <div className="font-bold">
                      {elem.repairShop.companyName}
                    </div>
                    {!isPhoneNew ? (
                      <div className="h-1/4 text-right font-bold text-xl">
                        {elem.price}, -&euro;
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="bg-slate-500 py-2 mt-2 text-center rounded-lg uppercase cursor-pointer lg:text-sm text-[12px]"
                  onClick={() => {
                    setDashboardForm2({
                      planId: isPhoneNew ? elem.repairShop._id : elem._id,
                      workshopName: elem.repairShop.companyName,
                      workshopEmail: elem.repairShop.emailAddress,
                      price: elem.price,
                    });
                    setDashboardCurrentComp("form3");
                  }}
                >
                  {!isPhoneNew ? "reparaturschein generieren" : "Preis anfragen" }
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="border border-white w-20 text-center py-1 rounded cursor-pointer"
          onClick={() => {
            setDashboardForm({
              manufacturer: "",
              manufacturerName: "",
              model: "",
              modelName: "",
              damage: [],
              ort: "",
              modelPrice: "",
              isPhoneNew: false,
            });
            setDashboardCurrentComp("form1");
          }}
        >
          zurück
        </div>
      </div>
    </>
  );
};

export default Form2;
